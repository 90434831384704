import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';

import './index.scss';

class News extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <ul className="list__news">
        {posts &&
          posts.map(({ node: post }) => (
            <li key={post.id}>
              <div className="text__news">
                <span>{post.frontmatter.date}</span>
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
              </div>
              <Link to={post.fields.slug} className="arrow-btn">
                <span>→</span>
              </Link>
            </li>
          ))}
      </ul>
    );
  }
}

News.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query NewsQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 5
          filter: {
            frontmatter: {
              templateKey: { eq: "news-post" }
              tags: { in: "news" }
            }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "YYYY.MM.DD")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <News data={data} count={count} />}
  />
);
