import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import './index.scss';

const Project = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <ul className="list__project-list">
        <li>
          <div className="img__project-list">
            <Link to="/projects">
              <Img fluid={data.creative.childImageSharp.fluid} />
            </Link>
            <Link to="/projects" className="square-btn">
              <span>READ MORE ></span>
            </Link>
          </div>
          <div className="heading__project-list">
            <h3>クリエティブ事業</h3>
          </div>
          <div className="heading-text-white">
            <p>
              イラスト制作/漫画制作/その他デザイン業務についてのご相談を受け付けています。
            </p>
          </div>
        </li>
        <li>
          <div className="img__project-list">
            <a href="https://store.stings.jp/" target="_blank" rel="noreferrer">
              <Img fluid={data.wagon.childImageSharp.fluid} />
            </a>
          </div>
          <div className="heading__project-list">
            <h3>eコマース事業</h3>
          </div>
          <div className="heading-text-white">
            <p>
              やさうさちゃんをはじめとした自社キャラクターIPのオリジナルグッズを販売しております。
            </p>
          </div>
        </li>
      </ul>
    )}
  />
);

export default Project;

const query = graphql`
  query {
    creative: file(relativePath: { eq: "creative.png" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    wagon: file(relativePath: { eq: "wagon.png" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
