import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import News from '../components/News';
import Project from '../components/Project';
import './index-page.scss';

export const IndexPageTemplate = ({ image, vision, mission, helmet }) => (
  <div className="content">
    {helmet || ''}
    <section className="main-visual">
      <Img fluid={image.main_visual.childImageSharp.fluid} />
      <div className="main-logo">
        <Img fluid={image.logo.childImageSharp.fluid} />
      </div>
      <div className="main-caps">
        <p className="main-caps-scroll">SCROLL</p>
      </div>
    </section>
    <section className="vision">
      <div className="vision__inner">
        <div className="section-heading-blue">
          <h2>VISION</h2>
        </div>
        <div className="heading-blue">
          <h3>{vision.heading}</h3>
        </div>
        <p className="heading-text-blue">{vision.description}</p>
        <div className="bg-vision1">
          <Img fluid={image.yasausa_vision.childImageSharp.fluid} />
        </div>
        <div className="bg-vision2">
          <Img fluid={image.yasausa_vision2.childImageSharp.fluid} />
        </div>
      </div>
    </section>
    <section className="mission">
      <div className="mission__inner">
        <div className="section-heading-white">
          <h2>MISSION</h2>
        </div>
        <div className="heading-white">
          <h3>{mission.heading}</h3>
        </div>
        <div className="heading-text-white">{mission.description}</div>
        <div className="bg__mission">
          <Img fluid={image.yasausa_mission.childImageSharp.fluid} />
        </div>
      </div>
    </section>
    <section className="news">
      <div className="news__inner">
        <div className="section-heading-blue">
          <h2>NEWS</h2>
        </div>
        <News />
        <div className="more-btn__news">
          <Link className="square-btn" to="/news">
            <span>ニュース一覧へ</span>
          </Link>
        </div>
      </div>
    </section>
    <section className="project-list">
      <div className="project-list__inner">
        <div className="section-heading-white">
          <h2>PROJECTS</h2>
        </div>
        <Project />
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  vision: PropTypes.object,
  mission: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        vision={frontmatter.vision}
        mission={frontmatter.mission}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${frontmatter.title}`}</title>
            <meta name="description" content={`${frontmatter.description}`} />
            <meta property="og:title" content={`${frontmatter.title}`} />
            <meta
              property="og:description"
              content={`${frontmatter.description}`}
            />
            <meta name="twitter:title" content={`${frontmatter.title}`}></meta>
            <meta
              name="twitter:description"
              content={`${frontmatter.description}`}
            ></meta>
          </Helmet>
        }
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          main_visual {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          logo {
            childImageSharp {
              fluid(maxWidth: 450, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          yasausa_vision {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          yasausa_vision2 {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          yasausa_mission {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }

        description
        vision {
          heading
          description
        }
        mission {
          heading
          description
        }
      }
    }
  }
`;
